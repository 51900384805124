// ProtectedRoute.js
import React from "react";
import { useAuth } from "../context/AuthContext";
import Login from "../screens/Login";

const ProtectedRoute = ({ children }) => {
	const { user } = useAuth();

	if (!user) {
		return <Login/> // Or redirect to login
	}

	return children;
};

export default ProtectedRoute;
