// App.js
import React, { useState } from "react";
import { AuthProvider } from "./context/AuthContext";
import { TimerProvider } from "./context/TimerContext"; // Import TimerProvider
import ProtectedRoute from "./components/ProtectedRoute";
import Tracker from "./screens/Tracker";
import Logger from "./screens/Logger";
import Profile from "./screens/Profile";
import { Timer, List, CircleUser } from "lucide-react";

const App = () => {
	const [currentScreen, setCurrentScreen] = useState(<Logger />);

	return (
		<AuthProvider>
			<TimerProvider>
				<ProtectedRoute>
					<div className="">
						{React.cloneElement(currentScreen, {})} {/* Pass props if needed */}
					</div>
					<div className="fixed bottom-0 w-full bg-white border-black border-t-[1px] flex justify-center gap-20 h-16 p-2 px-10">
						<button onClick={() => setCurrentScreen(<Tracker />)}>
							<Timer />
						</button>
						<button onClick={() => setCurrentScreen(<Logger />)}>
							<List />
						</button>
						<button onClick={() => setCurrentScreen(<Profile />)}>
							<CircleUser />
						</button>
					</div>
				</ProtectedRoute>
			</TimerProvider>
		</AuthProvider>
	);
};

export default App;
