// screens/Tracker.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Play, Pause } from "lucide-react";
import { useTimer } from "../context/TimerContext"; // Import useTimer hook
import PocketBase from "pocketbase"; // Import PocketBase here
import moment from "moment"; // Import moment here
import ConfettiExplosion from "react-confetti-explosion";

const Tracker = () => {
	const { user } = useAuth();
	const {
		time,
		setTime,
		task,
		setTask,
		isRunning,
		startTimer,
		stopTimer,
		resetTimer,
	} = useTimer(); // Use the timer context
	const [isExploding, setIsExploding] = useState(false);

	const formatTime = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const secs = seconds % 60;
		return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
			2,
			"0"
		)}`;
	};

	const handleSave = async () => {
		const pb = new PocketBase("https://pb_household_tracker.hom3lab.de");

		const data = {
			name: task,
			person: user.id,
			duration: time,
			date: moment().toISOString(),
		};

		await pb.collection("tasks").create(data); // Save the task
		setIsExploding(true); // Start the confetti explosion
		resetTimer(); // Reset timer after saving
		setTimeout(() => setIsExploding(false), 3000); // Stop the explosion after 3 seconds
	};

	return (
		<>
			{isExploding && <ConfettiExplosion />}

			<div className="flex flex-col items-center h-screen justify-between p-10 pb-20">
				<div className="flex flex-col gap-10 items-center mt-20">
					<div className="text-8xl font-bold text-center font-mono">
						{formatTime(time)}
					</div>
					<button onClick={() => (isRunning ? stopTimer() : startTimer())}>
						{isRunning ? <Pause size={36} /> : <Play size={36} />}
					</button>
				</div>

				<div className="w-full mb-20">
					<input
						type="text"
						placeholder="Aufgabe"
						className="border-2 rounded-md p-2 w-full"
						value={task}
						onChange={(e) => setTask(e.target.value)} // Update task using context
					/>
					<button
						className={`bg-blue-500 text-white rounded-md p-2 w-full mt-2 ${
							task === "" || time === 0 ? "opacity-50 cursor-not-allowed" : ""
						}`}
						onClick={handleSave}
						disabled={task === "" || time === 0}
					>
						Speichern
					</button>
				</div>
			</div>
		</>
	);
};

export default Tracker;
