import React, { useEffect, useState } from "react";
import PocketBase from "pocketbase";
import moment from "moment";

const Logger = () => {
	const [records, setRecords] = useState([]);
	const [winner, setWinner] = useState(null);
	const [distance, setDistance] = useState(null);

	useEffect(() => {
		fetchRecords();
	}, []);

	const fetchRecords = async () => {
		const pb = new PocketBase("https://pb_household_tracker.hom3lab.de");

		const records = await pb.collection("tasks").getFullList({
			expand: "person", sort: "-date"
		});

		setRecords(records);

		// Calculate the total duration for each person
		const durationsByPerson = {};
		records.forEach((record) => {
			const personName = record.expand.person.name;
			if (durationsByPerson[personName]) {
				durationsByPerson[personName] += record.duration;
			} else {
				durationsByPerson[personName] = record.duration;
			}
		});

		const winner = Object.keys(durationsByPerson).reduce((a, b) =>
			durationsByPerson[a] > durationsByPerson[b] ? a : b
		);
		const distance = Math.abs(
			durationsByPerson[winner] -
				durationsByPerson[
					Object.keys(durationsByPerson).filter(
						(person) => person !== winner
					)[0]
				]
		);

		setWinner(winner);
		setDistance((distance/60).toFixed(1));
	};


  return (
    <div className="p-10 pb-20 ">
      <div className="text-xl font-bold border-2 w-full p-2 rounded-md text-center mb-14">
        {winner} führt mit {distance} Minuten
      </div>
      <table className="min-w-full text-xs ">
        <thead className="border-b-2">
          <tr>
            <th className="py-2 text-left text-gray-600">Task</th>
            <th className="py-2 px-2 text-left text-gray-600">Duration</th>
            <th className="py-2 px-2 text-left text-gray-600">Person</th>
            <th className="py-2 px-2 text-left text-gray-600">Date</th>
          </tr>
        </thead>
        <tbody>
          {records.map((record) => (
            <tr key={record.id}>
              <td className="py-2">{record.name}</td>
              <td className="py-2 px-2">{record.expand.person.name}</td>
              <td className="py-2 px-2">{(record.duration/60).toFixed(1)}</td>
              <td className="py-2 px-2">
                {moment(record.date).format("DD.MM")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Logger;
