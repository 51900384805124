// Login.js
import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";

const Login = () => {
	const { login } = useAuth();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	const handleLogin = async (e) => {
		e.preventDefault();
		setError("");

		try {
			await login(username, password);
		} catch (err) {
			setError("Login failed. Please check your credentials.");
			console.error(err);
		}
	};

	return (
		<div className="m-10">
			<h1 className="text-2xl text-center mb-20">Haushaltstracker</h1>
			<form
				onSubmit={handleLogin}
				className="p-10 rounded-xl flex flex-col items-center justify-center"
			>
				<div className="flex flex-col gap-1 mb-4 w-full">
					<label htmlFor="username">Username</label>
					<input
						type="text"
						id="username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
						required
						className="border-2 rounded-md p-2"
					/>
				</div>
				<div className="flex flex-col gap-1 mb-4 w-full">
					<label htmlFor="password">Passwort</label>
					<input
						type="password"
						id="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
						className="border-2 rounded-md p-2"
					/>
				</div>
				{error && <p style={{ color: "red" }}>{error}</p>}
				<button
					type="submit"
					className="bg-blue-500 text-white rounded-lg px-4 py-2 mt-2 w-full"
				>
					Login
				</button>
			</form>
		</div>
	);
};

export default Login;
