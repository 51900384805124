// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import PocketBase from "pocketbase";

const pb = new PocketBase("https://pb_household_tracker.hom3lab.de");
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);

	useEffect(() => {
		// Check if user is already authenticated
		if (pb.authStore.isValid) {
			setUser(pb.authStore.model);
		}
	}, []);

	const login = async (username, password) => {
		const authData = await pb
			.collection("users")
			.authWithPassword(username, password);
		setUser(authData.record); // Set the user from the authentication data
	};

	const logout = () => {
		pb.authStore.clear();
		setUser(null);
	};

	return (
		<AuthContext.Provider value={{ user, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
