// context/TimerContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
	const [time, setTime] = useState(0);
	const [task, setTask] = useState("");
	const [isRunning, setIsRunning] = useState(false);

	useEffect(() => {
		let timer;
		if (isRunning) {
			timer = setInterval(() => {
				setTime((prevTime) => prevTime + 1); // Update time
			}, 1000);
		}
		return () => clearInterval(timer);
	}, [isRunning]);

	const startTimer = () => setIsRunning(true);
	const stopTimer = () => setIsRunning(false);
	const resetTimer = () => {
		setTime(0);
		setTask("");
		stopTimer();
	};

	return (
		<TimerContext.Provider
			value={{
				time,
				setTime,
				task,
				setTask,
				isRunning,
				startTimer,
				stopTimer,
				resetTimer,
			}}
		>
			{children}
		</TimerContext.Provider>
	);
};

export const useTimer = () => {
	return useContext(TimerContext);
};
