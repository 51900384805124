import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import PocketBase from "pocketbase";

const Profile = () => {
	const { user, logout } = useAuth();
	const [newRoommate, setNewRoommate] = useState("");

	const handleCopyID = () => {
		navigator.clipboard.writeText(user.id);
		alert("ID copied to clipboard!");
	};

	const handleAddRoomate = async () => {
		// Add the new roommate to the database
		const pb = new PocketBase("https://pb_household_tracker.hom3lab.de");

		const data = {
			roommate: newRoommate,
		};

		const record = await pb.collection("users").update(user.id, data);
		setNewRoommate("");
		console.log(record);
	};

	return (
		<div className="p-10 pb-20">
			<div className="flex justify-between">
				<h1 className="text-2xl"> 👋🏼 {user.name}</h1>

				<button className="bg-red-200 rounded-md px-4 py-2 " onClick={logout}>
					Logout
				</button>
			</div>
			<button
				className="p-1 px-2 bg-gray-100 rounded-md mt-4"
				onClick={handleCopyID}
			>
				Copy ID
			</button>
			<div className="mt-20 flex flex-col gap-2">
				<input
					type="text"
					className="w-full p-2 border-2 rounded-md"
					value={newRoommate}
					placeholder="Mitbewohner ID"
					onChange={(e) => setNewRoommate(e.target.value)}
				/>
				<button
					onClick={handleAddRoomate}
					className="p-2 bg-blue-500 text-white rounded-md"
				>
					Mitbewohner hinzufügen
				</button>
			</div>
		</div>
	);
};

export default Profile;
